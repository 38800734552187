.contact__container {
  grid-template-columns: repeat(2, max-content);
  justify-content: center;
  column-gap: 6rem;
  padding-bottom: 3rem;
}

.contact__title {
  text-align: left;
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1-5);
}

.contact__card-title:hover {
  color: #000;
}

.contact__info {
  display: grid;
  row-gap: 1rem;
}

.contact__form {
  margin-top: 1rem;
}
.contact__form-icons svg {
  font-size: 1.5rem;
}

.contact__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 0.75rem;
  text-align: center;
}

.contact__card:hover {
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.2);
}

.contact__card-icon {
  font-size: 1.5rem;
  color: var(--title-color);
  margin-bottom: 0.2rem;
}
.contact__card a {
  font-size: 1rem;
  color: var(--title-color);
  margin-bottom: 0.2rem;
}
.contact__form-tag {
  display: block;
}

.contact__form-input {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  font-size: var(--smaller-font-size);
  color: #4e4e4e;
  width: 100%;
  font-size: 16px;
}

.send-icon {
  margin-left: 0.5rem;
}

.booking_info {
  text-transform: capitalize;
  font-size: var(--small-font-size);
  color: var(--title-color);
}

.booking_info-icon {
  font-size: 1.1rem;
  margin-right: 0.3rem;
  color: #f70000;
}
@media screen and (max-width: 768px) {
  .contact__container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    column-gap: 1rem;
  }
}
