.offers {
  margin-bottom: 3rem;
}

.offers__items {
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  column-gap: 1.5rem;
}

.offers__items_link {
  display: block;
  background: #51b0de;
  color: #fff;
  transition: 0.5s;
  padding: 8px 20px;
  text-align: center;
  width: 50%;
  margin-top: 1rem;
  border-radius: 5px;
  margin: auto;
  opacity: 1;
}
.offers__items_link:hover {
  opacity: 0.75;
}

.offers__item-title {
  min-height: 100px;
  color: var(--title-color);
  text-align: center;
}
.offers__item-title span {
  font-size: var(--smaller-font-size);
}

.offers__img {
  border-radius: 15px;
  min-height: 300px;
}

@media screen and (max-width: 767px) {
  .offers {
    margin-bottom: 6rem;
  }
  .offers__items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .offers__items {
    grid-template-columns: repeat(1, 1fr);
  }
}
