/*======== GLOBAL STYLES ========*/

/** Google Fonts **/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

/** Slider **/
@import "~react-image-gallery/styles/css/image-gallery.css";

/**==== Gloabl Variables ====**/
:root {
  --header-height: 3rem;

  /** Colours **/
  --hue: 0;
  --sat: 0%;
  --title-color: hsl(var(--hue), var(--sat), 20%);
  --title-color-dark: hsl(var(--hue), var(--sat), 0%);
  --text-color: hsl(var(--hue), var(--sat), 46%);
  --body-color: hsl(var(--hue), var(--sat), 98%);
  --container-color: #fff;

  /** Fonts & typography **/
  --body-font: "Poppins", sans-serif;

  /** Fonts size **/
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  /** Fonts weight **/
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  /** Margin Bottom **/
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /** z index **/
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/**==== Slider ====**/
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 70px;
}

.image-gallery-slide .image-gallery-image {
  object-fit: cover;
}

.image-gallery-thumbnails-container {
  justify-content: center;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(80vh - 30px);
}

/**==== Gloabl Variables Mobile ====**/
@media screen and (max-width: 992px) {
  :root {
    /** Fonts size **/
    --big-font-size: 2.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/**==== Base ====**/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
  overflow-x: hidden;
}

h1,
h2,
h3 {
  color: var(--text-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

/**==== Reusable classes ====**/
.section {
  padding: 5rem 0 2rem;
}

.section__title-large {
  font-size: var(--big-font-size);
  color: var(--title-color);
}

.section__title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
}

.section_subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 4rem;
}

.section__title,
.section_subtitle {
  text-align: center;
}

.homepage_slider {
  min-height: 400px;
}

/**==== Layout ====**/
.container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

/**==== Buttons ====**/
.button {
  display: inline-block;
  background-color: #51b0de;
  border: 1px solid #51b0de;
  color: var(--container-color);
  padding: 0.5rem;
  border-radius: 5px;
  font-weight: var(--font-medium);
  opacity: 1;
}

.button:hover {
  opacity: 0.75;
}

.button__icon {
  margin-left: var(--mb-0-5);
}

.button--flex {
  display: inline-flex;
  align-items: center;
}

/**==== Testimonials ====**/

.testimonials__container {
  width: 750px;
}

.testimonials__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 1.5rem;
  margin-bottom: var(--mb-3);
  min-height: 8rem;
}

.testimonials__icon {
  font-size: 2rem;
  text-align: center;
  color: var(--title-color);
  width: 100%;
}

.testimonials__name {
  font-size: var(--h3-font-size);
  font-weight: var(--font-semi-bold);
  margin-bottom: var(--mb-0-25);
  text-align: center;
}

.testimonials__description {
  font-size: var(--small-font-size);
  text-align: center;
  margin-top: 0.5rem;
}

.swiper-pagination-bullet {
  background-color: var(--text-color) !important;
}
.swiper {
  padding: 1rem;
  width: auto;
}

/**==== Footer ====**/
.instagram__container > div > div {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 1rem;
  align-items: end;
}
.ig-instagram-link img {
  height: 200px;
  border-radius: 5px;
}
/**==== Footer ====**/
.footer__container {
  background: #ebebeb;
  font-size: var(--small-font-size);
}
.footer__inner {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: 3.5rem 1rem;
}
.developed {
  text-align: right;
}

.closure{
  max-width: 750px;
  margin: 2rem auto;
  border: 1px solid green;
  border-radius: 12px;
  padding: 1rem;
}

/**==== Breakpoints ====**/
@media screen and (max-width: 992px) {
  .container {
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }
  .button {
    padding: 1rem 1.75rem;
  }
}

@media screen and (max-width: 768px) {
  .section {
    padding: 1rem 0 2rem;
  }
  .footer__container {
    margin-bottom: 1rem;
  }
  .instagram__container > div > div {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 576px) {
  .instagram__container > div > div {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media screen and (max-width: 350px) {
  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
}
